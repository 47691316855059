<template>
  <div class="wrap">
    <div class="title">3A医药城隐私政策</div>
    <p class="dateMsg">
      本版发布日期:2021年12月3日
      <br />生效日期：2021年12月3日
    </p>

    <div class="wrap-in">
      <p class="text">概述</p>
      本政策将帮助您了解以下内容：
      <div class="secBox">
        <p class="BoldMsg">
          1.我们如何收集个人信息<br />
          2.我们如何使用Cookie、Beacon、Proxy等技术<br />
          3.我们如何使用个人信息<br />
          4.我们如何对外提供个人信息<br />
          5.我们如何保护您的个人信息安全<br />
          6.您如何访问和管理自己的个人信息<br />
          7.对第三方责任的声明<br />
          8.本政策的适用及更新<br />
          9.如何联系我们<br />
          10.本政策中关键词说明<br />
        </p>
      </div>
      <br />
      <p class="IndentBox">
        3A医药城（以下或称“我们”）尊重并保护用户（以下或称“您”）的隐私。在您使用3A医药城APP各项服务时，我们将按照3A医药城隐私权政策（以下简称“本政策”）收集、存储、使用及对外提供您的个人信息。同时，我们会通过本政策向您说明，我们如何为您提供访问、更新、管理和保护您的信息的服务。
        <span class="BoldMsg underLineMsg">
          本政策与您使用各项服务关系紧密，我们建议您在使用各项服务之前仔细阅读并理解本政策全部内容，做出您认为适当的选择。我们努力用通俗易懂、简明扼要的文字表达，并对本政策中与您的权益存在重大关系的条款，采用下划线或加粗方式进行标注，您应重点阅读，在充分理解并同意后使用相关产品或服务，一旦您开始使用3A医药城各项产品或服务，即表示您已充分理解并同意本政策。</span
        >
      </p>
      <p class="IndentBox">
        <span class="BoldMsg underLineMsg">
          需要特别说明的是，本政策不适用于其他第三方向您提供的服务，第三方向您提供的服务适用其向您说明的隐私权政策。</span
        >
      </p>
      <p class="IndentBox">
        为了遵守国家法律法规及监管规定，也为了向您提供服务及提升服务质量，我们需要收集、存储、使用及对外提供您的信息。您同意我们按照本政策约定处理您的信息，以便您享受优质、便捷、个性化的服务，同时更好地保护您的账户及交易安全。
      </p>
      <p class="text">一、我们如何收集个人信息</p>

      <p class="IndentBox">
        在您使用各项服务的过程中，在以下情形中我们需要收集您的信息，用以向您提供服务、提升服务质量、保障您的账户和交易安全以及符合国家法律法规及监管规定：
      </p>
      （一）依据法律法规及监管规定履行法定义务<br />
      <p class="IndentBox">
        针对各项服务，我们需要按照法律法规或监管规定履行相应的法定义务，例如：进行资质审核、采取风险防范措施。我们会在相应的用户服务协议当中向您说明，为了履行相应的法定义务，需要收集您的哪些个人信息。
        <span class="BoldMsg underLineMsg"
          >当您需要使用药采购服务时，为了遵守法律法规对交易资质的要求，对您的身份进行识别，3A医药城可能会收集您的身份信息、联系方式、账户及认证信息。同时，为了验证您提供信息的准确性和完整性，可能会与合法留存您的信息的国家机关、金融机构、企事业单位进行核对。</span
        >
      </p>
      （二）为您提供产品或服务<br />
      <p class="IndentBox">
        为了帮助您成为我们的会员并向您提供产品或服务（包括但不限于基础服务、附加服务、客服及争议处理服务），同时便于您查询您的交易状态或历史记录，
        <span class="BoldMsg underLineMsg">
          我们会保存您申请或使用服务过程当中提交或产生的必要信息：
        </span>
      </p>
      <div class="secBox">
        1.为便于向您提供我们的产品或服务，我们会记录您提供的信息，例如您提供的联系人姓名、联系电话等。
        <br />
        2.为向您提供更契合您需求的页面展示和搜索结果、了解产品适配性、识别账号异常状态，我们会收集关于您使用的服务以及使用方式的信息，即设备信息（包括设备型号、操作系统版本、设备设置、唯一设备标识符、设备环境、移动应用列表等软硬件特征信息）、设备所在位置相关信息（包括您授权的GPS位置以及WLAN接入点、蓝牙和基站等传感器信息）、日志信息，并将这些信息进行关联。同时，我们会根据您的浏览及搜索记录、设备信息、位置信息、订单信息，提取您的浏览、搜索偏好、行为习惯、位置信息等特征，基于特征标签进行间接人群画像并展示、推送信息。如果您不想接受我们给您发送的商业广告，您可通过短信提示回复退订或我们提供的其他方式进行退订。
        <br />
        3.为识别您的设备ID并预防恶意程序及反作弊、提高服务安全性、保障运营质量及效率，我们会手机您的设备信息（包括IMEI、MEID、Android ID、IMSI、GUID、MAC地址）、您安装的应用信息活运行中的进程信息。
        <br />
        4.当您在我们的产品及/或服务中订购具体商品及/或服务时，我们会通过系统为您生成购买该商品及/或服务的订单。在下单过程中,您需至少提供您的收货人姓名、收货地址、收货人联系电话，同时该订单中会载明您所购买的商品及/或服务信息、具体订单号、订单创建时间、您应支付的金额，我们收集这些信息是为了帮助您顺利完成交易、保障您的交易安全、查询订单信息、提供客服与售后服务及其他我们明确告知的目的。
        <br />
        5.为便于您了解查询订单信息并对订单信息进行管理和交易统计，我们会收集您在使用我们服务过程中产生的订单信息、账户信息用于向您展示及便于您对订单进行管理和交易统计，同时也用于我们向您推送新的订单通知。
        <br />
        6.为确认交易状态及为您提供交易争议解决服务，我们会通过您基于交易所选择的交易对象、交易方式、支付机构、物流公司等收集与交易进度相关的您的交易、支付、物流信息，或将您的交易信息共享给上述服务提供者。
        <br />
        7.当发生交易争议纠纷时，为保障您的账户及系统安全，我们需要您提供必要的个人信息以核验您的身份，为便于与您联系、尽快帮助您解决问题或记录相关问题的处理方案及结果，我们可能会保存您与我们的通信/通话记录及相关内容（包括账号信息、订单信息、您为了证明相关事实提供的其他信息，或您留下的联系方式信息），如果您针对具体订单进行咨询、投诉或提供建议的，我们会使用您的账号信息和订单信息。
        <br />
        8.为了提供服务及改进服务质量的合理需要，我们还可能使用的您的其他信息，包括您与客服联系时您提供的相关信息，您参与问卷调查时向我们发送的问卷答复信息。
        <br />
        9.为了使用我们的服务，您可以允许我们向您发送通知。如果您要关闭或开启新消息通知，您可以在设备的“设置”-“通知”功能中找到我们的App进行更改。
        <br />
        <span class="BoldMsg underLineMsg">
          请您注意，您开启任一权限即代表您授权我们可以收集和使用相关个人信息来为您提供对应服务，您一旦关闭任一权限即代表您取消了授权，我们将不再基于对应权限继续收集和使用相关个人信息，也无法为您提供该权限所对应的服务。但是，您关闭权限的决定不会影响此前基于您的授权所进行的信息收集及使用。
        </span>
      </div>
      （三）安全风险防范
      <p class="IndentBox">
        为了提高您使用服务的安全性，防止您个人信息被不法分子获取，我们需要记录您使用的服务类别、方式及相关操作信息，
        <span class="BoldMsg underLineMsg">
          例如：设备型号、IP地址、设备软件版本信息、设备识别码、设备标识符、所在位置、网络使用习惯以及其他与服务相关的日志信息。
        </span>
      </p>
      <p class="IndentBox">
        为提高您使用我们及我们关联公司、合作伙伴提供服务的安全性，保护您或其他用户或公众的人身财产安全免遭侵害，更好地预防钓鱼网站、欺诈、网络漏洞、计算机病毒、网络攻击、网络侵入等安全风险，更准确地识别违反法律法规或3A医药城相关协议规则的情况，
        <span class="BoldMsg underLineMsg">
          我们可能使用或整合您的会员信息、交易信息、设备信息、有关网络日志以及我们关联公司取得您授权或依据法律共享的信息，来综合判断您账号及交易风险、进行身份验证、检测及防范安全事件，并依法采取必要的记录、审计、分析、处置措施。
        </span>
      </p>
      （四）为了提供个性化服务及改进服务质量
      <p class="IndentBox">
        <span class="BoldMsg underLineMsg">
          为使您能使用我们的服务、提升您的服务体验及改进服务质量，或者为您推荐更优质或更适合的服务，我们的App会向您申请下列与个人信息相关的系统权限：
        </span>
      </p>
      <div class="secBox">
        1.基于位置信息的个性化推荐服务：我们会在您开启位置权限后访问获取您的位置信息，根据您的位置信息为您提供更契合您需求的页面展示、产品及/或服务，比如向您推荐附近的优惠信息；
        <br />
        2.基于相机/摄像头的附加服务：您可在开启相机/摄像头权限后使用该功能进行扫码用于登录、搜索商品，拍摄照片用于首次上传资质信息、订单评价、订单售后申请。
        <br />
        3.基于相册（图片库/视频库）的图片/视频访问及上传的附加服务：您可在开启相册权限后使用该功能上传您的照片/图片/视频，以实现上传资质信息、提交售后申请或与客服沟通提供证明等功能。我们可能会通过您所上传的照片/图片来识别您需要购买的商品或服务。
        <p class="IndentBox">
          <span class="BoldMsg">
            您理解并同意，上述附加服务可能需要您在您的设备中开启您的位置信息（地理位置）、摄像头（相机）、相册（图片库/存储）的访问权限，以实现这些权限所涉及信息的收集和使用。您可在您的设备设置或我们客户端中（访问手机设置-应用-权限管理-3A医药城）逐项查看上述权限的状态，并可自行决定这些权限随时的开启或关闭。请您注意，您开启任一权限即代表您授权我们可以收集和使用相关个人信息来为您提供对应服务，您一旦关闭任一权限即代表您取消了授权，我们将不再基于对应权限继续收集和使用相关个人信息，也无法为您提供该权限所对应的服务。但是，您关闭权限的决定不会影响此前基于您的授权所进行的信息收集及使用。
          </span>
        </p>
      </div>

      （五）其他用途
      <p class="IndentBox">
        <span class="BoldMsg underLineMsg">
          若您提供的信息中含有第三方的个人信息，在向3A医药城提供这些个人信息之前，您需确保您已经取得合法的授权。
        </span>
      </p>
      （六）根据相关法律法规及国家标准，在以下情形中，我们可能会依法收集并使用您的<span
        class="BoldMsg"
        >个人信息无需征得您的同意：</span
      >
      <div class="secBox">
        1.与国家安全、国防安全直接相关的；
        <br />
        2.与公共安全、公共卫生、重大公共利益直接相关的；
        <br />
        3.与刑事侦查、起诉、审判和判决执行等直接相关的；
        <br />
        4.出于维护您或他人的生命、财产等重大合法权益但又很难得到您本人同意的；
        <br />
        5.从合法公开披露的信息中收集个人信息，例如：合法的新闻报道、政府信息公开等渠道；
        <br />
        6.根据您的要求签订和履行合同所必需的；
        <br />
        7.用于维护所提供的服务的安全稳定运行所必需的，例如：发现、处置产品或服务的故障；
        <br />
        8.与我们履行法律法规规定的义务相关的；
        <br />
        9.法律法规规定的其他情形。
      </div>

      <p class="text">二、我们如何使用Cookie、Beacon、Proxy等技术</p>

      <p class="IndentBox">
        为使您获得更轻松的访问体验，您访问我们的网站或使用各项服务时，我们可能会通过小型数据文件识别您的身份，这么做可帮您省去重复输入注册信息的步骤，或者帮助判断您的账户安全状态。这些数据文件可能是Cookie，Flash
        Cookie，您的浏览器或关联应用程序提供的其他本地存储（以下简称“Cookie”）。
        <span class="BoldMsg"
          >请您理解，某些服务只能通过使用Cookie才可得到实现。</span
        >
        <span class="underLineMsg">
          如您的浏览器或浏览器附加服务允许，您可以修改对Cookie的接受程度或者拒绝相关Cookie。
        </span>
        多数浏览器工具条中的“帮助”部分会告诉您怎样防止您的浏览器接受新的Cookie，怎样让您的浏览器在您收到一条新Cookie时通知您或者怎样彻底关闭Cookie。
        <span class="underLineMsg">
          此外，您可以通过改变浏览器附加程序的设置，或通过访问提供商的网页，来关闭或删除浏览器附加程序使用的类似数据（例如：Flash
          Cookie）。
        </span>
        <span class="BoldMsg"
          >但这一举动在某些情况下可能会影响您安全访问我们的网站和使用相关服务。</span
        >
      </p>
      <p class="IndentBox">
        我们网站上还可能包含一些电子图像（以下简称“单像素GIF文件”或“网络Beacon”），使用网络Beacon可以帮助网站计算浏览网页的用户或访问某些Cookie，我们会通过网络Beacon收集您浏览网页活动的信息，
        <span class="BoldMsg"
          >例如：您访问的页面地址、您先前访问的援引页面的位置、您的浏览环境以及显示设定。</span
        >
      </p>
      <p class="IndentBox">
        <span class="BoldMsg"
          >如您通过我们的网站或APP，使用了由第三方而非我们的提供的服务时，我们无法保证这些第三方会按照我们的要求采取保护措施，为尽力确保您的账号安全，使您获得更安全的访问体验，我们可能会使用专用的网络协议及代理技术（以下简称“专用网络通道”或“网络代理”）。</span
        >
        使用专用网络通道，可以帮助您识别到我们已知的高风险站点，减少由此引起的钓鱼、账号泄露等风险，同时更有利于保障您和第三方的共同权益，阻止不法分子篡改您和您希望访问的第三方之间正常服务内容，例如：不安全路由器、非法基站等引起的广告注入、非法内容篡改等。
        <span class="BoldMsg"
          >在此过程中，我们也可能会获得和保存关于您计算机的相关信息，例如：IP地址、硬件ID。</span
        >
      </p>

      <p class="text">三、我们如何使用个人信息</p>
      （一）为了遵守国家法律法规及监管要求，以及向您提供服务及提升服务质量，或保障您的账户和资金安全，
      <span class="BoldMsg">我们会在以下情形中使用您的信息：</span>
      <div class="secBox">
        1.实现本政策中“我们如何收集个人信息”所述目的；
        <br />
        2.为了使您知晓使用服务的状态，我们会向您发送服务提醒；
        <br />
        3.为了保障服务的稳定性与安全性，我们会将您的信息用于身份验证、安全防范、诈骗监测、预防或禁止非法活动、降低风险、存档和备份用途；
        <br />
        4.根据法律法规或监管要求向相关部门进行报告；
        <br />
        5.邀请您参与各项产品或服务有关的客户调研；
        <br />
        6.对您的信息进行综合统计、分析加工，以便为您提供更加准确、个性、流畅及便捷的服务，或帮助评估、改善或设计产品、服务及运营活动等。
        <!-- <span class="BoldMsg"
          >我们可能根据前述信息向您提供营销活动通知、商业性电子信息或您可能感兴趣的推广信息，如您不希望接收此类信息，您可按照我们提示的方法选择退订。</span
        > -->
        <br />
      </div>
      （二）当我们要将信息用于本政策未载明的其他用途时，会按照法律法规及国家标准的要求
      <span class="BoldMsg"
        >以在app或官网首页公告、具体场景下的文案确认动作等形式再次征求您的同意。</span
      >

      <p class="text">四、我们如何对外提供个人信息</p>
      （一）共享 <br />
      <div class="secBox">
        1.业务共享
        <div class="IndentBox">
          （1）我们承诺对您的个人信息进行保密。
          <span class="BoldMsg underLineMsg">
            除法律法规及监管部门另有规定外，我们仅在以下情形中与第三方共享您的个人信息，第三方包括关联公司、服务提供商、合作金融机构以及其他合作伙伴。
          </span>
          在将您的个人信息提供给第三方前，我们将尽商业上合理的努力评估该第三方收集信息的合法性、正当性、必要性。我们会与第三方签订相关法律文件并要求第三方处理您的个人信息时遵守法律法规，要求第三方对您的个人信息采取保护措施。
        </div>
        <div class="IndentBox">
          目前，我们的合作伙伴主要包括代表我们分析服务类的授权合作伙伴、处理银行卡的支付机构、第三方验证机构。对我们与之共享个人信息的公司、组织和个人，我们会与其签署相应的保密协议，如获取您个人信息的公司、组织或个人超出本政策规定使用您个人信息的，其应当另行取得您的授权同意。目前关于我们接入的合作伙伴等第三方的SDK包括：
        </div>
      </div>

      <div class="TableBox">

 <el-table
    :data="tableData"
    style="width: 100%"
    >
    <el-table-column
      prop="SDK"
      label="SDK名称(安卓和IOS)"
       align="center"
      width="180">
    </el-table-column>
    <el-table-column
      prop="company"
      label="所属公司"
       align="center"
      width="120">
    </el-table-column>
    <el-table-column
      prop="usedMsg"
      label="使用目的"
       align="center"
      width="120">
    </el-table-column>
    <el-table-column
      prop="SDKMsg"
      label="SDK收集的个人信息类型"
       align="center"
      width="180">
    </el-table-column>
    <el-table-column
      prop="userMsg"
      label="权限获取"
      align="center"
     >
    </el-table-column>
    <el-table-column
      fixed="right"
      label="官网链接"
       align="center"
      width="300">
      <template slot-scope="scope">
        <el-button @click="linkTo(scope.row.webLink)" type="text" size="small">{{scope.row.webLink}}</el-button>
      </template>
    </el-table-column>
  </el-table>
        <!-- <img src="@/assets/bg.png" alt="" /> -->
      </div>

      <div class="IndentBox">
        （2）事先获得您明确同意的情况下，我们会在法律法规允许且不违背公序良俗的范围内，依据您的授权范围与第三方共享您的个人信息。
      </div>

      <div class="IndentBox">
        （3）您主动选择情况下共享：您通过3A医药城平台购买产品或服务，
        <span class="BoldMsg">
          我们会根据您的选择，将您订单信息中与交易有关的必要的个人信息共享给相关商品或服务提供者，
        </span>
        以实现您交易及售后服务需求。
      </div>

      <div class="IndentBox">
        （4）
        <span class="BoldMsg underLineMsg">
          向可信赖的合作伙伴或投资者披露您的个人信息，让他们根据我们的指示并遵循本政策以及其他任何相应的保密和安全措施来为我们处理这些信息。
        </span>
      </div>

      <div class="IndentBox">
        （5）在不透露单个用户个人信息资料的前提下，为了给用户提供更好的服务，
        <span class="BoldMsg underLineMsg">
          3A医药城可能会对整个用户数据库进行分析并对用户数据库进行商业上的利用（包括但不限于公布、分析或以其它合法方式使用用户访问量、访问时段、用户偏好等用户数据信息）
        </span>
      </div>
      <div class="secBox">
        2.投诉处理
        <div class="IndentBox">
          当您投诉他人或被他人投诉时，为了保护您及他人的合法权益，我们
          <span class="BoldMsg">
            可能会将您的姓名及有效证件号码、联系方式、投诉相关内容提供给被投诉方、有关行政主管部门及监管机关，以便及时解决投诉纠纷，但法律法规明确禁止提供的除外。
          </span>
        </div>
      </div>
      （二）转让
      <div class="secBox">
        <span class="BoldMsg">
          我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：
        </span>
        <div class="IndentBox">1.事先获得您的明确同意；</div>
        <div class="IndentBox">2.根据法律法规或强制性的行政或司法要求；</div>
        <div class="IndentBox">
          3.转让经去标识化处理的个人信息，且确保数据接收方无法重新识别或者关联个人信息主体；
        </div>
        <div class="IndentBox">
          4.在涉及资产转让、收购、兼并、重组或破产时，如涉及到个人信息转让，我们会向您告知有关情况，并要求新的持有您个人信息的公司、组织继续受本政策的约束。如变更个人信息使用目的时，我们将要求该公司、组织重新取得您的明示同意。如破产且无承接方的，我们将对数据做删除处理。
        </div>
      </div>
      （三）公开披露
      <div class="secBox">
        我们仅在以下情况下，公开披露您的个人信息：
        <div class="IndentBox">
          <span class="BoldMsg">
            1.在公布中奖活动名单时会脱敏展示中奖者手机号或账户登录名。
          </span>
        </div>
        <div class="IndentBox">
          2.获得您明确同意或基于您的主动选择，我们可能会公开披露您的个人信息。
        </div>

        <div class="IndentBox">
          <span class="BoldMsg underLineMsg">
            3.如果我们确定您出现违反法律法规或严重违反3A医药城相关协议规则的情况，或为保护3A医药城及其关联公司用户或公众的人身财产安全免遭侵害，我们可能会依据法律法规或3A医药城相关协议规则披露关于您的个人信息及您的店铺主体信息与处罚情况。
          </span>
        </div>
        <div class="IndentBox">
          除以上情形外，原则上我们不会将您的信息进行公开披露。如确需公开披露时，我们会向您告知并征得您的同意。
        </div>
      </div>
      （四）委托处理
      <div class="secBox">
        为了提升信息处理效率，降低信息处理成本，或提高信息处理准确性，我们可能会委托有能力的关联公司或其他专业机构代表我们来处理用户信息，但我们会通过书面协议、现场审计等方式要求受托公司遵守严格的保密义务及采取有效的保密措施，禁止其将这些信息用于未经您授权的用途。在委托关系解除时，要求受托公司不再保存个人信息。
      </div>
      （五）根据相关法律法规及国家标准，
      <span class="BoldMsg underLineMsg"
        >在以下情形中，我们可能会依法共享、转让、公开披露您的个人信息无需征得您的同意：</span
      >
      <div class="IndentBox">1.与国家安全、国防安全直接相关的；</div>
      <div class="IndentBox">
        2.与公共安全、公共卫生、重大公共利益直接相关的；
      </div>
      <div class="IndentBox">
        3.与犯罪侦查、起诉、审判和判决执行等直接相关的；
      </div>
      <div class="IndentBox">
        4.出于维护您或其他个人的生命、财产等重大合法权益但又很难得到您本人同意的；
      </div>
      <div class="IndentBox">5.您自行向社会公众公开的个人信息；</div>
      <div class="IndentBox">
        6.从合法公开披露的信息中收集的个人信息，例如：合法的新闻报道、政府信息公开等渠道。
      </div>
      <div class="IndentBox">
        <span class="BoldMsg underLineMsg"
          >根据法律规定，共享、转让经去标识化处理的个人信息，且确保数据接收方无法复原并重新识别个人信息主体的，不属于个人信息对外共享、转让及公开披露行为，对此类数据的保存及处理将无需另行向您通知并征得您的同意。</span
        >
      </div>

      <br />
      <p class="text">五、我们如何保护您的个人信息安全</p>
      （一）我们在保护您的个人信息上将不断努力，争取采取符合业界标准、合理可行的安全防护措施保护您提供的个人信息安全，防止个人信息遭到未经授权访问、公开披露、使用、修改、损坏或丢失。例如，在您的浏览器与服务器之间交换数据（如信用卡信息）时受SSL（Secure
      Socket
      Layer）协议加密保护；我们会使用加密技术提高个人信息的安全性；我们会使用受信赖的保护机制防止个人信息遭到恶意攻击；我们会部署访问控制机制，与相关处理数据的员工签署保密协议。尽力确保只有授权人员才可访问个人信息；以及我们会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。另外，我们还会聘请外部独立第三方对我们的信息安全管理体系进行评估。
      <br />
      （二）我们有行业先进的以数据为核心，围绕数据生命周期进行的数据安全管理体系，从组织建设、制度设计、人员管理、产品技术等方面多维度提升整个系统的安全性。
      <br />
      （三）我们会采取合理可行的措施，尽力避免收集无关的个人信息。我们只会在达成本政策所述目的所需的期限内和地域内（国内）保存您的个人信息，除非法律有强制的存留要求，例如《中华人民共和国电子商务法》要求商品和服务信息、交易信息保存时间自交易完成之日起不少于五年。而我们判断前述期限的标准包括：

      <div class="secBox">
        <div class="IndentBox">
          1.完成与您相关的交易目的、维护相应交易及业务记录、应对您可能的查询或投诉；
        </div>
        <div class="IndentBox">2.保证我们为您提供服务的安全和质量；</div>
        <div class="IndentBox">3.您是否同意更长的留存期间；</div>
        <div class="IndentBox">4.是否存在保留期限的其他特别约定；</div>
        <div class="IndentBox">5.法律法规规定的最短存储期限。</div>
        <div class="IndentBox">
          在您的个人信息超出保留期间后，我们会根据适用法律的要求删除您的个人信息，或使其匿名化处理。
        </div>
        <div class="IndentBox">
          为了提升信息处理效率，降低信息处理成本，或提高信息处理准确性，我们可能会委托有能力的关联公司或其他专业机构代表我们来处理用户信息，但我们会通过书面协议、现场审计等方式要求受托公司遵守严格的保密义务及采取有效的保密措施，禁止其将这些信息用于未经您授权的用途。在委托关系解除时，要求受托公司不再保存个人信息。
        </div>
      </div>
      （四）<span class="BoldMsg underLineMsg"
        >互联网并非绝对安全的环境，我们强烈建议您不要使用非3A医药城推荐的通信方式发送个人信息。</span
      >
      在使用3A医药城服务进行网上交易时，您不可避免地要向交易对方或潜在的交易对方披露自己的个人信息，如联络方式或联系地址。请您妥善保护自己的个人信息，仅在必要的情形下向他人提供。
      <span class="BoldMsg"
        >如您发现自己的个人信息尤其是您的账号或密码发生泄露，请您立即联络我们，以便我们根据您的申请采取相应措施。请注意，您在使用我们服务时自愿共享甚至公开分享的信息，可能会涉及您或他人的个人信息甚至个人敏感信息。</span
      >
      请您更加谨慎地考虑，是否在使用我们的服务时共享甚至公开分享相关信息。
      <br />
      （五）<span class="BoldMsg underLineMsg"
        >请使用复杂密码，协助我们保证您的账号安全。</span
      >
      <br />
      （六）在不幸发生个人信息安全事件后，我们将按照法律法规的要求向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。事件相关情况我们将以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，上报个人信息安全事件的处置情况。
      <br />
      <p class="text">六、您如何访问和管理自己的个人信息</p>
      （一）我们将采取适当的技术手段，保障您可以访问、更新和更正、删除您的个人信息、获取个人信息副本、注销账户、改变您授权同意的范围。如功能支持，您可以根据页面提示自助操作，或者可以联系相应的客服热线或通过在线客服联系我们。
      <br />
      （二）每个业务功能可能需要一些基本的个人信息才能得以完成。除此之外，对于额外个人信息的收集和使用，您可以通过登录账号或联系我们给予或收回您的授权同意。当您收回同意后，我们将不再处理相应的个人信息。但您收回同意的决定，不会影响此前基于您的授权而开展的个人信息处理。
      <br />
      （三）您可以通过访问3A医药城“我的”-“设置”-“注销账号”进行账户注销，当您符合约定的账户注销条件并注销某项服务相关账户后，您该账户内的所有信息将被清空，我们将不会再收集、使用或对外提供与该账户相关的个人信息，但我们仍需按照监管要求的时间保存您在使用服务期间提供或产生的信息，且在该依法保存的时间内有权机关仍有权依法查询。
      <br />
      （四）如您发现我们收集、使用您个人信息的行为，违反了法律法规规定或违反了与您的约定，您可联系相应的客服热线或通过在线客服，要求删除相应的信息。
      <br />
      （五）尽管有上述约定，但按照相关法律法规及国家标准，在以下情形中，我们可能无法响应您的请求：
      <div class="secBox">
        1.与国家安全、国防安全直接相关的；
        <br />
        2.与公共安全、公共卫生、重大公共利益直接相关的；
        <br />
        3.与刑事侦查、起诉、审判和执行判决等直接相关的；
        <br />
        4.有充分证据表明您存在主观恶意或滥用权利的；
        <br />
        5.响应您的请求将导致其他个人、组织的合法权益受到严重损害的；
        <br />
        6.出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人授权同意的；
        <br />
        7.涉及商业秘密的；
        <br />
        8.与我们履行法律法规规定的义务相关的。
      </div>
 <p class="text">七、对第三方责任的声明</p>
   <div class="IndentBox">
  <span class="BoldMsg underLineMsg">
请您注意，您的交易相对方、您访问的第三方网站经营者、通过我们接入的第三方服务（例如：我们的APP中第三方提供的应用）和通过我们接收您的个人信息的第三方可能有自己的隐私权保护政策；当您查看第三方创建的网页或使用第三方开发的应用程序时，这些第三方可能会放置他们自己的Cookie或像素标签，这些Cookie或像素标签不受我们的控制，且它们的使用不受本政策的约束。
        </span>
我们会尽商业上的合理努力去要求这些主体对您的个人信息采取保护措施，但我们无法保证这些主体一定会按照我们的要求采取保护措施，请您与他们直接联系以获得关于他们的隐私权政策的详细情况。如您发现这些第三方创建的网页或第三方开发的应用程序存在风险时，建议您终止相关操作以保护您的合法权益。
   </div>


      <p class="text">八、本政策的适用及更新</p>
      （一）3A医药城所有服务均适用本政策，除非相关服务已有独立的隐私权政策（例如：知识产权保护平台隐私权政策）或相应的用户服务协议当中存在特殊约定。
      <br />
      （二）发生下列重大变化情形时，我们会适时对本政策进行更新：
      <br />
      <div class="secBox">
        1.我们的基本情况发生变化，例如：兼并、收购、重组引起的所有者变更；
        <br />
        2.收集、存储、使用个人信息的范围、目的、规则发生变化；
        <br />
        3.对外提供个人信息的对象、范围、目的发生变化；
        <br />
        4.您访问和管理个人信息的方式发生变化；
        <br />
        5.数据安全能力、信息安全风险发生变化；
        <br />
        6.用户询问、投诉的渠道和机制，以及外部纠纷解决机构及联络方式发生变化；
        <br />
        7.其他可能对您的个人信息权益产生重大影响的变化。
      </div>

      <span class="BoldMsg"
        >（三）由于我们的用户较多，如本政策发生更新，我们将以APP推送通知、发送邮件/短消息或者在我们的官方网站发布公告的方式来通知您。</span
      >为了您能及时接收到通知，建议您在联系方式更新时及时通知我们。如您在本政策更新生效后继续使用相关服务，即表示您已充分阅读、理解并接受更新后的政策并愿意受更新后的政策约束。
      <br />
      （四）您可以在我们的官方网站或APP首页查看本政策。
      <br />

      <p class="text">九、如何联系我们</p>
      <div class="IndentBox">
        <span class="BoldMsg"
          >3A医药城已经成立隐私保护小组，如您对本政策存在任何疑问，或对于您的个人信息处理存在任何投诉、意见，请通过相应的客服热线、在线客服或业务员联系我们，客服部门将会同隐私保护小组及时答复您。为保障您的信息安全，我们需要先验证您的身份和凭证资料。我们已经建立客户投诉管理机制，包括跟踪流程。一般来说，我们将在验证通过后的三个工作日内处理完成，特殊情形下最长将在不超过15个工作日或法律法规规定期限内作出答复。</span
        >
      </div>

      <p class="text">十、本政策中关键词说明</p>
      （一）本政策中的“个人信息”指：以电子或者他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。
      <br />
      （二）本政策中的“3A医药城”指：山西复盛公健康药业有限公司及山西复盛公健康药业有限公司的所有关联公司。公司注册地址为：山西省晋中市祁县经济开发区朝阳西街4号。
      <br />
      （三）本政策中的“身份要素”是指：我们用于识别您身份的信息要素，例如：您的账户登录名、密码、短信校验码、电话号码、手机号码、证件号码及生物识别信息。
      <br />

      <!--  
      <p class="text">十、免责条款</p>
      1、3A医药城旨在为客户提供交易和资讯服务，平台不提供任何形式的投资建议。
      <br />2、本软件所提供的信息全部属于本公司独家资源，信息内容仅供参考，不能作为单一依据，建议网友根据实际情况结合信息内容，选择性采纳使用。
      <br />3、页面中存在部分内容为一般药品科普知识，仅供消费者参考学习，相关内容不代表产品功效或宣传内容，请理性阅读。
      <br />4、为了配合相关法律法规，本站于2019年10月对全部内容进行筛查，部分词汇采用***号代替过滤，望用户谅解。
      <br />5、任何由于黑客攻击、计算机病毒侵入或发作、因政府管制而造成的暂时性关闭等影响网络正常经营的不可抗力而造成的个人资料泄露、丢失、被盗用或被窜改等，平台应及时采取补救措施，不承担任何责任。
      <p class="text">十一、附则</p>
      1.本注册条款中有关条款若被权威机构认定为无效，不应影响其他条款的效力，也不影响本服务条款的解释、违约责任及争议解决的有关约定的效力。
      <br />2.用户在此再次保证已经完全阅读并理解了上述用户注册条款并自愿正式进入3A医药城入驻用户在线注册程序，接受上述所有条款的约束。 -->
    </div>
  </div>
</template>

<style lang="less" scoped>
.wrap {
  width: 1200px;
  margin: 0 auto;
  padding: 10px 0 30px 0;
  .BoldMsg {
    font-weight: bold;
  }
  .underLineMsg {
    text-decoration: underline;
  }
  .TableBox {
    padding: 20px 0;
    img {
      width: 100%;
      height: auto;
    }
  }
  .title {
    height: 40px;
    font-size: 20px;
    text-align: center;
    font-weight: 700;
  }
  .dateMsg {
    font-size: 14px;
    line-height: 24px;
    font-weight: 600;
  }
  .text {
    font-size: 16px;
    font-weight: 600;
    margin: 30px 0 10px 0;
  }
  .wrap-in {
    line-height: 24px;
    font-size: 14px;
    .IndentBox {
      text-indent: 28px;
    }
  }
  .wrap-note {
    color: red;
    text-decoration: underline;
    // background: #fff;
    // font-style: italic;
    padding: 0;
    span {
      font-weight: 600;
      font-size: 16px;
    }
  }
  .secBox {
    padding: 0 20px;
  }
}
</style>

<script>
import { mapMutations } from "vuex";
import { getToken } from "utils/session.js";
// SDK  SDK名称(安卓和IOS)
// company  所属公司
// usedMsg  使用目的
// SDKMsg  SDK收集的个人信息类型
// userMsg  权限获取
// webLink  官网链接
export default {
  name: "Agreement",
  data(){
     return {
        tableData: [
          {
            SDK: '银联',
            company: '中国银联股份有限公司',
            usedMsg: '使用支付宝支付时调用',
            SDKMsg: '设备信息、Wi-Fi地址',
            userMsg: '网络状态、Wifi状态、手机状态、读写存储空间',
            webLink: 'https://open.unionpay.com/tjweb/api/detail?apiSvcId=1061&index=1'
          }
          ,
          {
            SDK: '微信支付',
            company: '深圳市腾讯计算机系统有限公司',
            usedMsg: '使用微信支付时调用',
            SDKMsg: '设备信息、Wi-Fi地址',
            userMsg: '网络状态、Wifi状态、手机状态、读写存储空间',
            webLink: 'https://open.weixin.qq.com/'
          }
          ,
          {
            SDK: '微信分享',
            company: '深圳市腾讯计算机系统有限公司',
            usedMsg: '帮助用户分享内容至微信客户端',
            SDKMsg: '设备信息、Wi-Fi地址',
            userMsg: '网络状态、Wifi状态、手机状态、读写存储空间',
            webLink: 'https://open.weixin.qq.com/'
          }
           ,
          {
            SDK: '友盟SDK',
            company: '友盟同欣（北京）科技有限公司',
            usedMsg: '提供统计、crash收集功能、分享',
            SDKMsg: '设备信息(IMEI/Mac/Android ID/IDFA/OPENUDID/GUID/SIM 卡 IMSI/地理位置等)、Wi-Fi地址、网络信息',
            userMsg: '网络状态、Wifi状态、手机状态',
            webLink: 'https://developer.umeng.com/sdk'
          }
           ,
          {
            SDK: '高德地图',
            company: '高德软件有限公司',
            usedMsg: '提供定位和地图服务',
            SDKMsg: 'Wi-Fi地址、位置信息',
            userMsg: 'Wifi状态、位置信息',
            webLink: 'https://lbs.amap.com/api/android-sdk/summary/'
          }
          ,
          {
            SDK: 'Zxing',
            company: '开源SDK',
            usedMsg: '二维码识别',
            SDKMsg: '识别图片信息',
            userMsg: '摄像头、读存储空间',
            webLink: 'https://github.com/zxing/zxing'
          }
          ,
          {
            SDK: 'Fresco',
            company: '开源SDK',
            usedMsg: '图片加载',
            SDKMsg: '网络信息',
            userMsg: '网络状态',
            webLink: 'https://www.fresco-cn.org/'
          }
          ,
          {
            SDK: 'Glide',
            company: '开源SDK',
            usedMsg: '图片加载与缓存',
            SDKMsg: '网络信息',
            userMsg: '网络状态、读写存储空间',
            webLink: 'https://github.com/bumptech/glide'
          }
        ]
     }
  },
  //路由进入时  显示未登陆状态头部
  beforeRouteEnter: (to, from, next) => {
    next((vm) => {
      vm.changeFlag(true);
    });
  },
  //路由离开时 隐藏未登陆状态头部
  beforeRouteLeave(to, from, next) {
    if (getToken() != null) {
      this.changeFlag(false);
    }
    next();
  },
  methods: {
    ...mapMutations({
      changeFlag: "changeFlag",
    }),
    linkTo(url){
          // 跳外链接
          window.location.href = url;
    } 
  },
};
</script>
